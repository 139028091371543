<template>
  <b-container fluid>
    <b-row>
      <b-col>
        {{ status }}
        <b-spinner v-if="spinner" small label="Spinning"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'UserImpersonate',
    data () {
      return {
        status: '',
        spinner: true
      }
    },
    props: {
      id: String,
    },
    methods: {
      impersonate () {
        if (this.$root.$data.me.IsSuperUser !== 1) {
          this.status = 'Access denied'
        }

        this.status = 'Getting token for user'

        this.$http.get('users/' + this.id + '/impersonate-token')
          .then(response => {
            localStorage.setItem('impersonator', JSON.stringify({
              jwt: localStorage.getItem('jwt'),
              user: this.$root.$data.me
            }))
            localStorage.setItem('jwt', response.data.token)
            localStorage.setItem('debugInfoLevel', this.$root.$data.debugInfoLevel)

            location.href = '/'
          })
          .catch(() => {
            this.status = "Error occurred"
            this.spinner = false
          })
      }
    },
    mounted () {
      this.impersonate()
    }
  }
</script>